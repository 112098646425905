/*--------------------------------------------------------------
10. Post
----------------------------------------------------------------*/
.cs-post.cs-style1 {
  background-color: #fff;
  border-radius: 10px;

  &:hover {
    .cs-post_thumb_in {
      transform: scale(1);
    }
  }
  .cs-post_thumb {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    .cs-post_thumb_in {
      height: 100%;
      width: 100%;
      border-radius: inherit;
      transition: all 0.6s ease;
    }
  }
  .cs-post_info {
    padding: 20px 30px 22px;
  }
  .cs-post_title {
    font-size: 20px;
    margin-bottom: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $accent;
    }
  }
  
  .cs-text_btn {
    svg {
      font-size: 22px;
      margin-left: 5px;
    }
  }

}