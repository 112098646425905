/*--------------------------------------------------------------
2. Preloader
----------------------------------------------------------------*/
.cs-preloader {
  position: fixed;
  z-index: 99999;
  top: 0;
  width: 100%;
  height: 100vh;
}

.cs-preloader_bg {
  text-align: center;
  height: 100%;
  width: 100%;
}

.cs-preloader_in {
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px;
  border: 3px solid rgba($white, 0.3);
  border-radius: 50%;
}

.cs-preloader_in:after {
  content: "";
  border-width: 3px;
  border-style: solid;
  border-color: rgba($accent, 0.2);
  border-top-color: $accent;
  border-radius: 50%;
  position: absolute;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  left: 50%;
  top: 50%;
  animation: spin 1s ease-in-out infinite;
  margin-left: -60px;
  margin-top: -60px;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
