/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #EEF2F9;
$black: #0a2540; 
$primary: #01275f;  // fonts primarias (titulos e etc)
$secondary: #141414;  //fonts
$ternary: #4a536e;
$border: #EEF2F9;
$gray: #EEF2F9;
$accent: #4A73f4; // btn
$accent2: #4A73f4;  // btn
