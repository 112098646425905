/*--------------------------------------------------------------
11. Pricing Table
----------------------------------------------------------------*/
.cs-pricing_table.cs-style1 {
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
  }
  .cs-pricing_head {
    padding: 30px 50px 25px;
    border-bottom: 1px dotted $border;
  }
  .cs-pricing_body {
    padding: 25px 50px 30px;
  }
  .cs-pricing_lable {
    color: $accent2;
    font-size: 14px;
    line-height: 1.6em;
  }
  .cs-pricing_icon {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: linear-gradient(257.51deg, #F2FCFE 34.07%, #FAF1FE 100%);
    flex: none;
    margin-right: 20px;
  }
  .cs-pricing_heading {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .cs-pricing_title {
    font-size: 20px;
    margin: 0;
  }
  .cs-price {
    b {
      font-weight: 700;
      font-size: 60px;
      display: inline-block;
      line-height: 1em;
    }
  }
  .cs-pricing_feature {
    color: $primary;
    li {
      padding-left: 28px;
      position: relative;
      svg {
        position: absolute;
        left: 0;
        top: 5px;
      }
      &:not(:last-child) {
        margin-bottom: 14px;
      }
      &.cs-inactive {
        color: $ternary;
        svg {
          fill: $ternary;
          path {
            fill: $ternary;
          }
        }
        span {
          display: inline-block;
          position: relative;

        }
      }
    }
  }
  .cs-pricing_btn {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .cs-pricing_table.cs-style1 {
    .cs-pricing_head {
      padding: 30px 40px 25px;
    }
    .cs-pricing_body {
      padding: 25px 40px 30px;
    }
  }
}
@media screen and (max-width: 575px) {
  .cs-pricing_table.cs-style1 .cs-price b {
    font-size: 50px;
  }
}