.banner-flag {
    background-color: #f9f9f9;
    padding: 20px 0;
    text-align: center;
    overflow: hidden; /* Esconde o conteúdo que sai da área visível */
  }
  
  .banner-flag-heading {
    font-size: 1.5rem;
    color: #01275f; /* Cor da fonte ajustada */
    margin-bottom: 20px;
  }
  
  .marquee {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }
  
  .marquee-content {
    display: flex;
    animation: scroll 20s linear infinite; /* Animação contínua */
    white-space: nowrap;
  }
  
  .marquee-content img {
    width: 80px;
    height: auto;
    margin-right: 20px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0); /* Início do movimento */
    }
    100% {
      transform: translateX(-100%); /* Move para fora da tela */
    }
  }
  