/*--------------------------------------------------------------
9. Iconbox
----------------------------------------------------------------*/
.cs-iconbox.cs-style1 {
  box-shadow: 0px 10px 50px rgba(28, 37, 40, 0.07);
  border-radius: 10px;
  background-color: #fff;
  padding: 40px 30px 37px 30px;
  position: relative;
  .cs-iconbox_icon {
    height: 70px;
    width: 70px;
    flex: none;
    border-radius: 50%;
    background-color: $accent;
    margin-bottom: 28px;
    transition: all .25s linear;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 0 0 2px rgba($accent, 0.25);
      opacity: 0;
      transform: scale(0.9);
    }
  }
  .cs-iconbox_number {
    font-weight: 600;
    font-size: 60px;
    line-height: 1em;
    color: transparent;
    -webkit-text-stroke: 1px $accent2;
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0.3;
    transition: all 0.3s ease;
  }
  .cs-iconbox_title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  &:hover {
    .cs-iconbox_icon {
      transform: scale(0.94);
      &::after {
        animation: sonarEffect 1s ease-out 75ms;
      }
    }
    .cs-iconbox_number {
      opacity: 0.6;
    }
  }
  &.cs-type1 {
    display: flex;
    padding: 30px 30px 25px 30px;
    .cs-iconbox_icon {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}
.cs-iconbox.cs-style2 {
  background-color: #fff;
  border-radius: 10px;
  min-height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  .cs-iconbox_icon {
    flex: none;
    margin-right: 15px;
  }
  .cs-iconbox_title {
    font-size: 20px;
    margin: 0;
    text-align: left;
  }
}
.cs-iconbox.cs-style3 {
  display: flex;
  .cs-iconbox_title {
    font-size: 16px;
    margin-bottom: 0;
  }
  .cs-iconbox_icon {
    flex: none;
    margin-right: 20px;
  }
  .cs-iconbox_icon {
    max-width: 35px;
    display: flex;
    align-items: center;
  }
}
@keyframes sonarEffect {
  0% {
      opacity: 0.3;
  }
  40% {
      opacity: 0.5;
      box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px rgba($accent, 0.25), 0 0 0 10px rgba(255,255,255,0.5);
  }
  100% {
      box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px rgba($accent, 0.25), 0 0 0 10px rgba(255,255,255,0.5);
      transform: scale(1.5);
      opacity: 0;
  }
}
@media screen and (max-width: 1400px) {
  .cs-iconbox.cs-style2 {
    min-height: 100px;
    padding: 25px 15px;
    .cs-iconbox_icon {
      margin-right: 12px;
      img {
        max-width: 58px;
      }
    }
    .cs-iconbox_title {
      font-size: 18px;
    }
  }
}