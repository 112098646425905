.banner-header {
  background-color: #01275f; 
  overflow: hidden; 
  white-space: nowrap;
  position: relative;
}

.marquee {
  display: flex;
  align-items: center;
  height: 50px; 
  position: relative;
}

.marquee-content {
  display: flex;
  animation: scroll 10s linear infinite; 
  white-space: nowrap; 
}

.marquee-content span {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff; 
  padding: 0 20px;
}

@keyframes scroll {
  0% {
    transform: translateX(0); 
  }
  100% {
    transform: translateX(-100%); 
  }
}

.marquee-content {
  display: flex;
  gap: 0; 
  width: max-content;
  animation: scroll 25s linear infinite;
}
